import React from 'react'
import LegalPageLayout from '../../components/LegalPageLayout/legalPageLayout'

const Privacy = () => {


    const bodyContent = () => <div>
                        <p>TheIncLAB (“TIL”) is committed to preserving the privacy of all visitors to www.theinclab.com. Please read the following privacy policy to understand how we use and protect the information that you provide to us. By registering on this site, you consent to the collection, use and transfer of your information under the terms of this policy.</p>
                        <h3>1. INFORMATION THAT WE COLLECT FROM YOU</h3>
                        <p>1.1 When you visit, register on or submit enquiries to www.theinclab.com you may be asked to provide certain information about yourself including your name and contact details. We may also collect information about your usage of our website as well as information about you from messages you post to the website and email or letters you send to us.</p>
                        <h3>2. USE OF YOUR INFORMATION</h3>
                        <p>2.1 Your information will enable us to provide you with access to all parts of our site and to supply the services you have requested. It will also enable us to contact you where necessary concerning your query. We will also use and analyze the information we collect so that we can administer, support, improve and develop our business.</p>
                        <p>2.2 Where you have consented, we might also use your information to let you know about other products and services which we offer which may be of interest to you and we may contact you by post, telephone or fax, as well as by email. If you change your mind about being contacted in the future, please let us know.</p>
                        <h3>3. DISCLOSURE OF YOUR INFORMATION</h3>
                        <p>3.1 The information you provide to us will be held on our computers in the US and may be accessed by or given to our staff and third parties who act for us for the purposes set out in this policy or for other purposes approved by you. Those parties process information, fulfill and deliver orders and provide support services on our behalf. We may also pass aggregate information on the usage of our site to third parties but this will not include information that can be used to identify you.</p>
                        <p>3.2 If our business enters into a joint venture with or is sold to or merged with another business entity, your information may be disclosed to our new business partners or owners.</p>
                        <p>3.3 Countries outside the US do not always have strong data protection laws. However, we will always take steps to ensure that your information is used by third parties in accordance with this policy.</p>
                        <p>3.4 Unless required to do so by law, we will not otherwise share, sell or distribute any of the information you provide to us without your consent.</p>
                        <h3>4. COOKIES</h3>
                        <p>4.1 Cookies are small amounts of information which we store on your computer. They also allow us to monitor website traffic and to personalize the content of the site for you. You may set up your computer to reject cookies, however, if you do, you may not be able to use certain features of our site.</p>
                        <h3>5. SECURITY AND DATA RETENTION</h3>
                        <p>5.1 We employ security measures to protect your information from access by unauthorized persons and against unlawful processing, accidental loss, destruction and damage. We will retain your information for a reasonable period or as long as the law requires.</p>
                        <h3>6. CHANGES TO OUR PRIVACY POLICY</h3>
                        <p>6.1 Any changes to our privacy policy in the future will be posted to the site and, where appropriate.</p>
                        <h3>7. CONTACT</h3>
                        <p>7.1 All comments, queries and requests relating to our use of your information are welcomed and should be sent to&nbsp;contact@theinclab.com</p>		
    </div>


    return (
        <div>
            <LegalPageLayout 
            bannerImg='/img/careers-hub.jpg'
            bannerTitle='Privacy'
            bodyContent={bodyContent()}
            />
        </div>
    )
}

export default Privacy
