import React from 'react'
import PageBannerImgBackground from '../PageBannerImgBackground/pageBannerImgBackground'
import Layout from '..//Layout'

import './legalPageLayout.scss'


const IndividualPageLayoutTemplate = ({ 
    bannerImg,
    bannerTitle,
    bannerDescription,
    bodyContent, 
     }) => {


    return (
        <Layout> 
        <div className='legal-page-layout-wrapper'> 
        <PageBannerImgBackground
        imgPath={bannerImg}
        title={bannerTitle}
        description={bannerDescription}
        />
        <div className='body-content-wrapper'>
         {bodyContent}
        </div>
    </div>
    </Layout>
    )
}


export default  IndividualPageLayoutTemplate
